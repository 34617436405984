<template>
  <div class="white mainBlack--text rounded gotham-font">
    <div class="d-sm-flex align-center mb-10" :class="[$vuetify.breakpoint.smAndDown ? 'pa-2' : 'px-4  py-4']">
      <div class="flex d-flex">
        <div>
          <div class="align-self-center" :class="[$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4']">
            {{ title }}
          </div>
          <div v-if="subtitle" class="text-body-2">{{ subtitle }}</div>
        </div>
      </div>
      <div :class="[$vuetify.breakpoint.smAndDown ? '' : 'text-right']">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import VueRouter from '../../router';

export default {
  name: 'PageHeader',
  props: {
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({}),
  methods: {
    // nav() {
    //   const baseRegistrationName = 'app.dashboard';
    //   VueRouter.push({ name: baseRegistrationName, params: { regId: this.app.RegistrationID } });
    // },
  },
  computed: {
    // app() {
    //   return this.$store.state.registration.app;
    // },
  },
};
</script>

<style scoped>
.icon {
  width: 80px;
  padding: 0 15px 0 0;
}
</style>
